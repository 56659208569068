window.obsstudio.getCurrentScene((scene) => {
  const obsSceneName = document.querySelector("#obs-scene .name");
  obsSceneName.textContent = JSON.stringify(scene);
});

const obsBrowserVersion = document.querySelector(
  "#obs-browser-version .version"
);
obsBrowserVersion.textContent = window.obsstudio.pluginVersion;

class Inspector {
  constructor(startTime, startEventName, endEventName, elementId) {
    this.start = startTime;
    this.element = document.querySelector(`#${elementId} .time`);

    if (startEventName) {
      window.addEventListener(startEventName, (event) => {
        this.start = Date.now();
      });
    }

    if (endEventName) {
      window.addEventListener(endEventName, (event) => {
        this.start = -1;
      });
    }
  }

  getTimeText(start) {
    if (start < 0) {
      return "N/A";
    } else {
      return Date.now() - start;
    }
  }

  update() {
    this.element.textContent = this.getTimeText(this.start);
  }
}

const inspectors = [
  new Inspector(Date.now(), null, null, "browser"),
  new Inspector(-1, "obsSourceVisibleChanged", null, "visible"),
  new Inspector(-1, "obsStreamingStarted", "obsStreamingStopped", "streaming"),
  new Inspector(-1, "obsRecordingStarted", "obsRecordingStopped", "recording"),
];

const animationStep = () => {
  inspectors.forEach((ins) => ins.update());
  requestAnimationFrame(animationStep);
};
animationStep();
